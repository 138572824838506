import { Route, Router, Set } from '@redwoodjs/router'

import { Environments, EnvVariables } from 'src/constants'

import { useAuth } from './auth'
import AppLandingLayout from './layouts/AppLandingLayout/AppLandingLayout'
import AppStateContextLayout from './layouts/AppStateContextLayout/AppStateContextLayout'
import AuthGateLayout from './layouts/AuthGateLayout/AuthGateLayout'
import CitizenLayout from './layouts/CitizenLayout/CitizenLayout'
import GovAdminLayout from './layouts/GovAdminLayout/GovAdminLayout'
import GovernmentLayout from './layouts/GovernmentLayout/GovernmentLayout'
import GovNonReviewerLayout from './layouts/GovNonReviewerLayout/GovNonReviewerLayout'
import GovRecordTypeLayout from './layouts/GovRecordTypeLayout/GovRecordTypeLayout'
import GovWellStaffLayout from './layouts/GovWellStaffLayout/GovWellStaffLayout'
import SidebarLayout from './layouts/SidebarLayout/SidebarLayout'
import RecordsInspectionsPage from './pages/RecordsInspectionsPage/RecordsInspectionsPage'

const Routes = () => {
  const isProduction = EnvVariables.Environment === Environments.Production

  return (
    <Router useAuth={useAuth}>
      {/* All routes MUST live within the AppStateContextLayout */}
      <Set wrap={AppStateContextLayout}>
        <Route notfound page={NotFoundPage} />
        <Route path="/404" page={NotFoundPage} name="notFound" />
        <Route path="/signing-up" page={SigningUpPage} name="signingUp" />
        <Set wrap={AppLandingLayout}>
          <Route path="/" page={LoginPage} name="home" />
          <Route path="/login" page={LoginPage} name="login" />
          <Route path="/signup" page={SignupPage} name="signUp" />
        </Set>

        <Set wrap={SidebarLayout}>
          {/* All routes that require authentication MUST live within the AuthGateLayout */}
          <Set wrap={AuthGateLayout}>
            {/* GovWellStaff */}
            <Set wrap={GovWellStaffLayout}>
              <Route path="/admin/orgs" page={AdminOrgsPage} name="adminOrgs" />
              <Route path="/admin/orgs/new" page={NewOrgPage} name="newOrg" />
              <Route path="/admin/orgs/{slug:String}" page={AdminOrgPage} name="adminOrg" />
              <Route path="/admin/users" page={AdminUsersPage} name="adminUsers" />
              <Route path="/admin/user-ids-lookup" page={UserIdsLookupPage} name="adminUserIdsLookup" />
            </Set>

            {/* Government-only */}
            <Set wrap={GovernmentLayout}>
              <Set wrap={GovAdminLayout}>
                <Route path="/configure" redirect="modules" />
                <Route path="/document-templates" page={DocumentTemplatesPage} name="documentTemplates" />
                <Route path="/document-templates/{uuid:String}" page={DocumentTemplatePage} name="editDocumentTemplate" />
                <Route path="/email-templates" page={EmailTemplatesPage} name="emailTemplates" />
                <Route path="/email-templates/{id:Int}" page={EmailTemplatePage} name="emailTemplate" />
                <Route path="/settings/fees" page={FeesPage} name="fees" />
                <Route path="/inspection-templates" page={InspectionTemplatesPage} name="inspectionTemplates" />
                <Route path="/inspection-templates/{id:Int}" page={EditInspectionTemplatePage} name="editInspectionTemplate" />
                <Route path="/new-email-template" page={NewEmailTemplatePage} name="newEmailTemplate" />
                <Route path="/organization" page={OrganizationPage} name="organization" />
                <Route path="/plan-reviews" page={PlanReviewsPage} name="planReviews" />
                <Route path="/record-template/{recordTemplateUuid:String}" page={RecordTemplateConfigurationPage} name="recordTemplateConfiguration" />
                <Route path="/record-type/{recordTypeId:Int}/form" page={EditRecordTypeFormPage} name="editRecordTypeForm" />
                <Route path="/reporting" page={ReportTemplatesPage} name="reporting" />
                <Route path="/task-templates" page={TaskTemplatesPage} name="taskTemplates" />
                <Route path="/task-templates/{taskTemplateId:Int}" page={EditTaskTemplatePage} name="taskTemplate" />
                <Route path="/violation-settings" page={ViolationSettingsPage} name="violationSettings" />
                <Route path="/settings/code-books" page={CodeBookSettingsPage} name="codeBookSettings" />
                <Route path="/settings/gis-attributes" page={GisAttributesPage} name="gisAttributes" />
                <Route path="/settings/rule-automations" page={RuleAutomationsPage} name="ruleAutomations" />
              </Set>

              <Set wrap={GovNonReviewerLayout}>
                <Set wrap={GovRecordTypeLayout}>
                  <Route path="/record-type/{recordTypeId:Int}" page={RecordsListPage} name="recordsList" />
                </Set>
                <Route path="/payments" page={PaymentTransfersPage} name="paymentsOld" />
                <Route path="/transactions" page={PaymentTransfersPage} name="paymentTransfers" />
                <Route path="/fees" page={RecordTaskFeesPage} name="recordTaskFees" />
                <Route path="/invoices" page={RecordTaskPaymentsPage} name="recordTaskPayments" />
                <Route path="/cases" page={ViolationsPage} name="codeComplaints" />
                <Route path="/cases/{identifier:String}" page={CodeComplaintPage} name="codeComplaint" />
                <Route path="/code-inspections" page={CodeInspectionsPage} name="codeInspections" />
                <Route path="/locations/address/{addressDisplayName:String}" page={AddressPage} name="addressOverview" />
                <Route path="/locations/parcel/{parcelDisplayName:String}" page={ParcelPage} name="parcelOverview" />
                <Route path="/settings/modules" page={ModulesPage} name="modules" />
                <Route path="/settings/modules/{recordTypeId:Int}" page={ModulePage} name="module" />
              </Set>
              <Route path="/company/{companyUuid:String}" page={CompanyPage} name="company" />
              <Route path="/contacts" page={ContactsPage} name="contacts" />
              <Route path="/record-collection/{recordCollectionUuid:String}" page={RecordCollectionPage} name="recordCollection" />
              <Set wrap={GovRecordTypeLayout}>
                <Route path="/record-type/{recordTypeId:Int}/inspections" page={RecordsInspectionsPage} name="recordsInspections" />
                <Route path="/record-type/{recordTypeId:Int}/plan-reviews" page={RecordsPlanReviewsPage} name="recordsPlanReviews" />
              </Set>
              <Route path="/stamp-templates" page={StampTemplatesPage} name="stampTemplates" />
              <Route path="/stamp-templates/{uuid:String}" page={StampTemplatePage} name="editStampTemplate" />
              <Route path="/signature" page={SignaturePage} name="signature" />
              <Route path="/user/{userUuid:String}" page={UserPage} name="user" />
            </Set>

            <Set wrap={CitizenLayout}>
              <Route path="/projects" redirect="citizenRecords" />
              <Route path="/records" page={CitizenRecordsPage} name="citizenRecords" />
              <Route path="/inspections" page={CitizenInspectionsPage} name="citizenInspections" />
              <Route path="/company-profile" page={CompanyProfilePage} name="companyProfile" />
              <Route path="/company-profile/new" page={NewCompanyPage} name="newCompanyProfile" />
              <Route path="/company-profile/edit" page={EditCompanyPage} name="editCompanyProfile" />
            </Set>

            <Route path="/settings" page={SettingsPage} name="settings" />

            <Route path="/{slug:String}/{recordUuid:String}/edit" page={EditRecordDraftPage} name="editRecordDraft" />
            <Route path="/record/{recordUuid:String}" page={RecordPage} name="record" />

            {/* Document generation routes */}
            <Route path="/record/{recordUuid:String}/generate-documents" page={RecordGenerateDocumentsPage} name="recordGenerateDocuments" />
            <Route path="/record/{recordUuid:String}/generate-task-documents/{taskUuid:String}" page={RecordTaskGenerateDocumentsPage} name="recordTaskGenerateDocuments" />
            <Route path="/record/{recordUuid:String}/regenerate-document/{documentUuid:String}" page={RegenerateDocumentPage} name="regenerateDocument" />

            <Route path="/select-jurisdiction" page={ApplicantCityPickPage} name="applicantCityPick" />
            <Route path="/tasks" page={TasksPage} name="tasks" />
            <Route path="/user-profile" page={UserProfilePage} name="userProfile" />
            {!isProduction && <Route path="/communications" page={CommunicationsPage} name="communications" />}
            {!isProduction && <Route path="/new-campaign" page={NewCampaignPage} name="newCampaign" />}
            {!isProduction && <Route path="/campaigns/{campaignId:Int}" page={CampaignPage} name="campaign" />}

            {/* for convenience */}
            {/* <Route path={'/pdf'} page={PDFPage} name="pdf" /> */}
          </Set>

          {/* TODO ! ! ! organization slugs may conflict with the names of our actual routes ! ! ! need to prefix all external or all internal routes (https://www.notion.so/govwell/Prefix-routes-to-prevent-conflicts-between-internal-and-external-organization-slug-routes-948168cc3d7f41e78f7e3a060e2c2a0b?pvs=4) */}
          <Route path="/{slug:String}/{recordTypeId:Int}/apply" page={RecordTypeApplyPage} name="recordTypePickOld" />
          <Route path="/{slug:String}/workflow/{recordTemplateUuid:String}/apply" page={RecordTemplateApplyPage} name="recordTemplateApply" />
          <Route path="/{slug:String}/{recordTypeId:Int}/pick" page={RecordTemplatePickPage} name="recordTemplatePick" />
          <Route path="/{slug:String}/submit-complaint" page={SubmitComplaintPage} name="submitComplaint" />
          <Route path="/{slug:String}/complaints/submit" page={SubmitComplaintV2Page} name="submitComplaintRecordType" />
          <Route path="/{slug:String}/pay" page={CityPayPage} name="cityPay" />
          <Route path="/{slug:String}/pay/{recordIdentifierOrRecordIssuedIdentifier:String}" page={CityPayPage} name="cityPaySpecific" />
        </Set>

        {/* The organization home page is the one exception that should always be a topbar layout, do not move it into the SidebarLayout */}

        {/* TODO ! ! ! organization slugs may conflict with the names of our actual routes ! ! ! need to prefix all external or all internal routes (https://www.notion.so/govwell/Prefix-routes-to-prevent-conflicts-between-internal-and-external-organization-slug-routes-948168cc3d7f41e78f7e3a060e2c2a0b?pvs=4) */}
        <Route path="/{slug:String}" page={HomePage} name="cityHome" />
        <Route path="/{slug:String}/cases/track" page={CitizenTrackViolationsPage} name="citizenTrackViolations" />
        <Route path="/{slug:String}/cases/{violationUuid:String}" page={ViolationCitizenPage} name="violationCitizen" />
      </Set>
    </Router>
  )
}

export default Routes
