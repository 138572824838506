import { fragmentRegistry } from '@redwoodjs/web/apollo'

import { CodeBookItemFragment, CodeBookItemOptionFragment } from 'src/lib/fragments/codeBookItems'
import { CodeBookFragment } from 'src/lib/fragments/codeBooks'
import { EnumerationFragment } from 'src/lib/fragments/enumerations'
import { RecipientContactFieldFragment } from 'src/lib/fragments/fields'
import { FileOnRecordFragment } from 'src/lib/fragments/fileOnRecord'
import { GeneratedFileFragment } from 'src/lib/fragments/files'
import { FormulaFragment, FormulaOptionFragment } from 'src/lib/fragments/formulas'
import { PlanReviewFieldGroupFragment, PlanReviewFileFragment } from 'src/lib/fragments/planReview'
import { RecordAccessFragment } from 'src/lib/fragments/recordAccess'
import { RecordEmailFileFragment } from 'src/lib/fragments/recordEmailFiles'
import { RecordEmailFragment } from 'src/lib/fragments/recordEmails'
import {
  RecordFragment,
  RecordOptionFragment,
  RelatedRecordFragment,
} from 'src/lib/fragments/records'
import { RecordSettingsFragment } from 'src/lib/fragments/recordSettings'
import { RecordTaskChecklistItemFragment } from 'src/lib/fragments/recordTasks'
import { RecordTaskTemplateGroupRequestedRecordFragment } from 'src/lib/fragments/recordTaskTemplateGroupRequestedRecordConfig'
import { RecordTaskTemplateGroupOptionFragment } from 'src/lib/fragments/recordTaskTemplateGroups'
import {
  RecordTemplateOptionFragment,
  RecordTemplatePickFragment,
} from 'src/lib/fragments/recordTemplates'
import { RecordTypeApplicationGroupFragment } from 'src/lib/fragments/recordTypes'
import {
  ViolationCodeFragment,
  ViolationCommentFragment,
  ViolationFileFragment,
  ViolationFragment,
  ViolationInspectionFragment,
  ViolationMailerFragment,
  ViolationMailerTitleFragment,
  ViolationTypeFragment,
} from 'src/lib/fragments/violations'
import {
  WorkflowFragment,
  WorkflowOptionFragment,
  WorkflowRecordTaskFragment,
  WorkflowRecordTaskGroupFragment,
} from 'src/lib/fragments/workflows'
import { WorkflowTemplateOptionFragment } from 'src/lib/fragments/workflowTemplates'

const UserDataFragment = gql`
  fragment UserDataFragment on User {
    id
    uuid
    clerkUserId
    createdAt
    organizationId
    firstName
    lastName
    officePhone
    emailAddress
    imageUrl
    type
    roles
    title
    isGovWellStaff
    canViewForeignValues
    cellPhone
    isPermittingEmployeeReference
    isLandingPageEmployeeReference
    isCodeViolationsEmployeeReference
    group
    isPlanReviewer
    status
    isInspector
  }
`

const UserNameFragment = gql`
  fragment UserNameFragment on User {
    firstName
    lastName
  }
`

const UserDataFragmentWithCompany = gql`
  fragment UserDataFragmentWithCompany on User {
    ...UserDataFragment
    company {
      ...CompanyFragment
    }
  }
`

const ViolationTypeHistoryFragmentNoUserData = gql`
  fragment ViolationTypeHistoryFragmentNoUserData on Violation {
    violationHistory {
      violationTypeOther
      createdAt
      violationTypes {
        id
        name
        description
      }
    }
    previousViolationHistory {
      violationTypeOther
      createdAt
      violationTypes {
        id
        name
        description
      }
    }
  }
`

const ViolationTypeHistoryFragment = gql`
  fragment ViolationTypeHistoryFragment on ViolationHistory {
    violationTypeOther
    createdAt
    status
    violationTypes {
      id
      name
      description
    }
    createdByUser {
      ...UserDataFragment
    }
  }
`

const TaskTemplatesFragment = gql`
  fragment TaskTemplateFragment on TaskTemplate {
    id
    identifier
    status
  }
`

const BaseFileFragment = gql`
  fragment BaseFileFragment on File {
    name
    id
    downloadableName
    isAllowedOnRecordPlansSets
    pageProcessingFinishedAt
    path
    size
    type
    createdAt
    createdByUser {
      firstName
      lastName
    }
    importedAt
  }
`

const FileFragment = gql`
  fragment FileFragment on File {
    ...BaseFileFragment
    url {
      signedUrl
    }
  }
`

const ThumbnailFileFragment = gql`
  fragment ThumbnailFileFragment on File {
    ...BaseFileFragment
    thumbnailUrl {
      signedUrl
    }
  }
`

const FilePageFragment = gql`
  fragment FilePageFragment on FilePage {
    id
    fileId
    heightPx
    pageNumber
    rotationDegrees
    signedThumbnailImageUrl
    widthPx
  }
`

const FileUploadCredentialsFragment = gql`
  fragment FileUploadCredentialsFragment on FileUploadCredentials {
    accessKeyId
    secretAccessKey
    sessionToken
    region
    bucket
    key
  }
`

const FieldFragment2 = gql`
  fragment FieldFragment on Field {
    id
    label
    required
    tooltip
    type
    foreignValueType
    userHasAccessToForeignValue
    parameters
    order
    checked
    num
    str
    strs
    recordId
    basedOnFieldId
    allowMultiple
    inheritParentRecordsPrimaryAddress
    codeBookItems {
      id
    }
    contact {
      id
      firstName
      lastName
      middleName
      email
      companyName
      phoneNumber
      faxNumber
      address
      title
      addressLine1
      addressLine2
      city
      state
      zip
    }
    record {
      id
      issuedIdentifier
      identifier
      submittedAt
      stringified
      recordTemplate {
        id
        name
      }
      applicantUser {
        id
        firstName
        lastName
        company {
          id
          name
        }
      }
    }
    inspectionResult {
      id
      name
      isPassing
    }
    locationSnapshot {
      ...LocationSnapshotFragment
    }
    file {
      ...FileFragment
    }
    files {
      ...FileFragment
    }
    recordTemplates {
      id
      name
    }
    recordCollections {
      id
      name
    }
    foreignValue {
      id
      type
    }
  }
  fragment LocationSnapshotFragment on LocationSnapshot {
    id
    parcelDisplayName
    locationObjectId
  }
`

const FieldGroupFragment = gql`
  fragment FieldGroupFragment on FieldGroup {
    id
    createdAt
    name
    fields {
      ...FieldFragment
    }
  }
`

const FieldGroupFragmentWithBasedOnFieldIds = gql`
  fragment FieldGroupFragmentWithBasedOnFieldIds on FieldGroup {
    id
    createdAt
    fields {
      ...FieldFragment
      basedOnFieldId
    }
  }
`

const ViolationFieldsFragment = gql`
  fragment ViolationFields on Violation {
    id
    uuid
    address
    status
    identifier
    createdAt
    reportedAt
    submitterType
    locationSnapshot {
      id
      parcelCoordinates {
        latitude
        longitude
      }
    }
    ownerUser {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    ...ViolationTypeHistoryFragmentNoUserData
    violationMailers {
      id
    }
  }
`

const ContactFragment = gql`
  fragment ContactFragment on Contact {
    id
    firstName
    lastName
    middleName
    email
    companyName
    phoneNumber
    faxNumber
    address
    title
    addressLine1
    addressLine2
    city
    state
    zip
  }
`

const ViolationInspectionsFragment = gql`
  fragment ViolationInspectionFields on ViolationInspection {
    id
    scheduledFor
    loggedAt
    status
    createdByUser {
      ...UserDataFragment
    }
    violation {
      id
      identifier
      address
      status
      createdAt
      locationSnapshot {
        id
        parcelCoordinates {
          latitude
          longitude
        }
      }
      ...ViolationTypeHistoryFragmentNoUserData
    }
  }
`

const OrganizationFragmentNoUsers = gql`
  fragment OrganizationFragmentNoUsers on Organization {
    id
    uuid
    slug
    city
    state
    department
    contactInfo
    supportsMultipleViolationTypes
    logoUrl
    additionalMailerContent
    inspectionCoordinationType
    hasCodeEnforcementModule
    showCodeEnforcementModule
    timeZone
    displayName
    addressEntryType
  }
`

const OrganizationFragmentWithUsers = gql`
  fragment OrganizationFragmentWithUsers on Organization {
    ...OrganizationFragmentNoUsers
    users {
      ...UserDataFragment
    }
  }
`

const RecordFragmentOverview = gql`
  fragment RecordFragmentOverview on Record {
    id
    createdAt
    submittedAt
    expirationDate
    tags
    identifier
    issuedIdentifier
    status {
      id
      type
      name
    }
    applicantFormatted
    isDraft
    uuid
    recordCollections {
      id
    }
    rejectedAt
    currentRecordTaskGroup {
      type
      recordTaskTemplateGroup {
        taskTemplate {
          identifier
          id
        }
      }
    }
    addressField {
      ...AddressFieldFragment
    }
    applicantUser {
      ...UserDataFragment
      company {
        id
        name
      }
    }
    ownerUser {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    recordTemplate {
      id
      name
      recordTypeId
      recordType {
        id
        name
        recordName
        isContact
        category
        allowInspections
        allowFees
        organization {
          slug
          city
          state
          displayName
        }
      }
      recordSettingsDerived {
        ...RecordSettingsFragment
      }
    }
    resolvedAt
    currentCaseHistory {
      id
      violationType {
        id
        name
      }
    }
  }
`

const RecordFragmentOverviewForApplicant = gql`
  fragment RecordFragmentOverviewForApplicant on Record {
    ...RecordFragmentOverview
    ownerUser {
      organization {
        slug
        state
        city
        displayName
      }
    }
  }
`

const PlanReviewCommentFragment = gql`
  fragment PlanReviewCommentFragment on PlanReviewComment {
    id
    comment
    isRevised
    xCoordinate
    yCoordinate
    filePageId
    filePage {
      id
      fileId
    }
    createdAt
    updatedAt
    status
    createdByUser {
      ...UserDataFragment
    }
    reviewId
    review {
      id
      recordPlansSet {
        id
        versionNumber
      }
    }
    childrenComments {
      id
      comment
      isRevised
      createdAt
      updatedAt
      createdByUser {
        ...UserDataFragment
      }
    }
  }
`

const PlanReviewStampFragment = gql`
  fragment PlanReviewStampFragment on PlanReviewStamp {
    id
    filePageId
    x
    y
    createdAt
    stampedAt
    stampTemplate {
      id
      width
      height
      params
      showBorder
      items {
        id
        x
        y
        widthPx
        heightPx
        type
        params
        file {
          id
          url {
            signedUrl
          }
        }
      }
    }
  }
`

const PlanReviewLineArrowFragment = gql`
  fragment PlanReviewLineArrowFragment on PlanReviewLineArrow {
    id
    filePageId
    startX
    startY
    endX
    endY
    thicknessPx
    color
    type
  }
`

const PlanReviewCircleFragment = gql`
  fragment PlanReviewCircleFragment on PlanReviewCircle {
    id
    filePageId
    startX
    startY
    endX
    endY
    thicknessPx
    color
    type
  }
`

const PlanReviewQuadrilateralFragment = gql`
  fragment PlanReviewQuadrilateralFragment on PlanReviewQuadrilateral {
    id
    filePageId
    startX
    startY
    endX
    endY
    thicknessPx
    color
  }
`

const EmailTemplateFragment = gql`
  fragment EmailTemplateFragment on EmailTemplate {
    id
    name
    subject
    content
    signature
    type
    createdByUser {
      ...UserDataFragment
    }
  }
`

const DocumentTemplateFragment = gql`
  fragment DocumentTemplateFragment on DocumentTemplate {
    id
    name
  }
`

const GeneralLedgerNumberFragment = gql`
  fragment GeneralLedgerNumberFragment on GeneralLedgerNumber {
    id
    glNumber
    description
  }
`

const FeeVersionFragment = gql`
  fragment FeeVersionFragment on FeeVersion {
    id
    type
    amount
    minimumAmount
    maximumAmount
    calculatedOn
    valuationMultiplier
    fee {
      id
      name
      description
    }
    generalLedgerNumber {
      ...GeneralLedgerNumberFragment
    }
  }
`

const FeeFragment = gql`
  fragment FeeFragment on Fee {
    id
    isArchived
    name
    description
    latestVersion {
      id
      type
      amount
      minimumAmount
      maximumAmount
      calculatedOn
      valuationMultiplier
      generalLedgerNumber {
        ...GeneralLedgerNumberFragment
      }
    }
  }
`

const PaymentTransferFragment = gql`
  fragment PaymentTransferFragment on PaymentTransfer {
    id
    createdAt
    totalAmountCents
    serviceFeeCents
    revenueCents
    failureCode
    failureMessage
    state
    type
    paymentMethod
    finixMerchantId
    checkNumber
    notes
  }
`

const RecordTaskFragment = gql`
  fragment RecordTaskFragment on RecordTask {
    id
    dueDate
    completedAt
    name
    type
    ownerUser {
      ...UserDataFragment
    }
    status
    recordTaskGroup {
      record {
        uuid
        identifier
        issuedIdentifier
        addressField {
          ...AddressFieldFragment
        }
        recordTemplate {
          recordTypeId
          name
        }
      }
    }
    recordTaskTemplate {
      taskTemplate {
        identifier
      }
    }
  }
`

const InspectionTemplateFragment = gql`
  fragment InspectionTemplateFragment on InspectionTemplate {
    id
    archivedAt
    name
    createdAt
    fieldGroup {
      ...FieldGroupFragment
    }
    createdByUser {
      ...UserDataFragment
    }
  }
`

const InspectionResultFragment = gql`
  fragment InspectionResultFragment on RecordTaskInspectionResult {
    id
    name
    isPassing
    isActive
    createdAt
    createdByUser {
      ...UserDataFragment
    }
  }
`

const RecordTaskInspectionAttemptFragment = gql`
  fragment RecordTaskInspectionAttemptFragment on RecordTaskInspectionAttempt {
    id
    status
    recordTaskInspectionId
    hasReport
    recordTaskInspection {
      visibleToApplicant
      inspectionTemplate {
        id
        name
      }
      attempts {
        hasReport
        id
        result {
          id
          isPassing
        }
      }
      inspectionGroup {
        id
        name
        recordTaskGroup {
          id
          isLocked
          name
          ordinal
          workflow {
            id
            completedAt
            createdAt
            status
            workflowTemplate {
              name
            }
          }
        }
      }
    }
    result {
      id
      isPassing
      name
    }
    inspectorComments
    scheduledFor
    requestedAt
    requestedFor
    inspectionDate
    loggedAt
    schedulingNotes
    requestedByUser {
      ...UserDataFragment
    }
    inspectorUser {
      ...UserDataFragment
    }
    isLatestAttempt
  }
`

const ViewRecordTaskInspectionAttemptFragment = gql`
  fragment ViewRecordTaskInspectionAttemptFragment on RecordTaskInspectionAttempt {
    id
    inspectionStartedAt
    inspectionEndedAt
    fieldGroup {
      ...FieldGroupFragment
    }
    hasReport
    recordTaskInspection {
      inspectionTemplate {
        name
      }
    }
    inspectorUser {
      ...UserDataFragment
    }
    loggedAt
    loggedByUser {
      ...UserDataFragment
    }
    scheduledAt
    scheduledFor
    scheduledByUser {
      ...UserDataFragment
    }
    requestedAt
    requestedFor
    requestedByUser {
      ...UserDataFragment
    }
    result {
      id
      name
      isPassing
    }
  }
`

const RecordTaskInspectionFragment = gql`
  fragment RecordTaskInspectionFragment on RecordTaskInspection {
    id
    order
    attempts {
      ...RecordTaskInspectionAttemptFragment
    }
    inspectionTemplate {
      name
    }
    visibleToApplicant
  }
`

const RecordTaskInspectionGroupFragment = gql`
  fragment RecordTaskInspectionGroupFragment on RecordTaskInspectionGroup {
    id
    name
    order
    alwaysAvailableToApplicants
    inspections {
      ...RecordTaskInspectionFragment
    }
  }
`

const RecordTypeFragment = gql`
  fragment RecordTypeFragment on RecordType {
    id
    addressEntryType
    allowFees
    allowInspections
    applyDescription
    category
    displayName
    icon
    isContact
    issuedIdentifierAlgorithm
    name
    recordName
    shouldEmailCitizens
    shouldEmailStaff
    shouldShowInStaffNavigation
    visibleOnOrganizationHomepage
    recordSettings {
      ...RecordSettingsFragment
    }
  }
`

const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    uuid
    name
    phoneNumber
    emailAddress
    addressLine1
    addressLine2
    zip
    city
    state
  }
`

const DocumentTemplateItemParamsFragment = gql`
  fragment DocumentTemplateItemParamsFragment on DocumentTemplateItem {
    params {
      letterhead {
        left
        right
        centerText
        centerFileId
      }
      headerText {
        size
        bold
        position
        text
        underline
      }
      applicantInfo {
        showApplicantInfo
        applicantHeader
        showCompanyInfo
        companyHeader
      }
      customField {
        renderLabel
        position
      }
      recordTemplateField {
        renderLabel
        position
      }
      gap {
        sizePx
      }
      permitType {
        position
      }
      inspectionsList {
        type
      }
      documentNumber {
        position
        prefix
      }
    }
    file {
      ...FileFragment
    }
    field {
      ...FieldFragment
    }
    imageFile {
      ...FileFragment
    }
  }
`

const GenerateDocumentTemplateFragment = gql`
  fragment GenerateDocumentTemplateFragment on DocumentTemplate {
    id
    name
    customFieldItems {
      id
      field {
        ...FieldFragment
      }
    }
    recordTemplateFieldItems {
      id
      field {
        basedOnFieldId
        ...FieldFragment
      }
    }
  }
`

const ApplyFormRecordTemplateFragment = gql`
  fragment ApplyFormRecordTemplateFragment on RecordTemplate {
    id
    uuid
    applicantType
    icon
    name
    tagline
    submissionPageText
    whenRequired
    applicationGroup {
      id
      name
    }
    fileFields {
      ...FieldFragment
    }
    fieldGroups {
      ...FieldGroupFragment
    }
    defaultWorkflowTemplate {
      recordTaskTemplateGroups {
        id
        feeConfigs {
          id
          visible
          fee {
            ...FeeFragment
            latestVersion {
              applicantExplanation
            }
          }
        }
        inspectionGroups {
          id
          inspections {
            id
            inspectionTemplate {
              id
              name
            }
          }
        }
      }
    }
    recordSettingsDerived {
      ...RecordSettingsFragment
    }
    recordType {
      ...RecordTypeApplyFragment
    }
  }
`
const RecordTypePickFragment = gql`
  fragment RecordTypePickFragment on RecordType {
    id
    applyDescription
    category
    displayName
    icon
    name
    recordName
  }
`
const RecordTypeApplyFragment = gql`
  fragment RecordTypeApplyFragment on RecordType {
    ...RecordTypePickFragment
    addressEntryType
    organization {
      id
      uuid
      slug
      city
      state
      displayName
    }
  }
`

const RecordTaskPaymentFragment = gql`
  fragment RecordTaskPaymentFragment on RecordTaskPayment {
    invoiceNumber
    paidByUser {
      ...UserDataFragment
    }
    requestedAt
    paidAt
    organizationFinixMerchant {
      id
      description
      finixMerchantId
    }
    record {
      applicantUser {
        ...UserDataFragment
      }
      identifier
      issuedIdentifier
      uuid
      recordTemplate {
        name
        recordType {
          name
          recordName
        }
      }
    }
  }
`
const RecordTaskFeeValuationFragment = gql`
  fragment RecordTaskFeeValuationFragment on RecordTaskFeeValuation {
    id
    quantity
    valuationVersion {
      id
      amountCents
      valuation {
        id
        name
        calculatedOn
      }
    }
  }
`
const RecordComponentRecordTaskFeeFragment = gql`
  fragment RecordComponentRecordTaskFeeFragment on RecordTaskFee {
    id
    quantity
    description
    calculationExplanation
    feeVersion {
      ...FeeVersionFragment
      staffExplanation
      applicantExplanation
    }
    total
    valuations {
      ...RecordTaskFeeValuationFragment
    }
  }
`
const RecordComponentPaymentTransferFragment = gql`
  fragment RecordComponentPaymentTransferFragment on PaymentTransfer {
    id
    createdAt
    paymentMethod
    revenueCents
    serviceFeeCents
    state
    totalAmountCents
    type
  }
`
const RecordComponentRecordTaskPaymentFragment = gql`
  fragment RecordComponentRecordTaskPaymentFragment on RecordTaskPayment {
    id
    isPayable
    isUndoable
    status
    paidAt
    paymentMethodsLabel
    requestedAt
    voidedAt
    recordTaskId
    totalWhenInvoiceRequestedCents
    invoiceNumber
    invoiceFile {
      ...FileFragment
    }
    receiptFile {
      ...FileFragment
    }
    requestedByUser {
      ...UserDataFragment
    }
    paidByUser {
      ...UserDataFragment
    }
    voidedByUser {
      ...UserDataFragment
    }
    organizationFinixMerchant {
      id
      description
      finixMerchantId
    }
    recordTask {
      id
      name
      status
      recordTaskGroup {
        id
        name
        workflow {
          id
          completedAt
          createdAt
          status
          workflowTemplate {
            name
          }
        }
      }
    }
    recordTaskFees {
      ...RecordComponentRecordTaskFeeFragment
    }
    paymentTransfers {
      ...RecordComponentPaymentTransferFragment
    }
  }
`

const RecordTaskFeeFragment = gql`
  fragment RecordTaskFeeFragment on RecordTaskFee {
    id
    recordTaskPayment {
      ...RecordTaskPaymentFragment
    }
    fee {
      id
      name
      latestVersion {
        id
        generalLedgerNumber {
          glNumber
        }
      }
    }
    processedByUser {
      ...UserDataFragment
    }
  }
`

const RecordTaskPlanReviewOverviewFragment = gql`
  fragment RecordTaskPlanReviewOverviewFragment on RecordTask {
    id
    dueDate
    name
    type
    ownerUser {
      ...UserDataFragment
    }
    status
    plansSetReview {
      id
      recordPlansSet {
        id
        versionNumber
      }
    }
    recordTaskGroup {
      id
      record {
        id
        identifier
        issuedIdentifier
        uuid
        addressField {
          ...AddressFieldFragment
        }
        recordTemplate {
          recordTypeId
          name
        }
      }
    }
  }
`

const PlanReviewRecordFragment = gql`
  fragment PlanReviewRecordFragment on Record {
    id
    identifier
    issuedIdentifier
    recordTemplate {
      id
      recordType {
        id
        name
        recordName
        addressEntryType
      }
    }
    plansSets {
      id
      versionNumber
    }
    formFieldGroups {
      ...FieldGroupFragment
    }
    allPlanReviewComments {
      ...PlanReviewCommentFragment
    }
    allPlanReviewCodeBookItems {
      ...CodeBookItemOptionFragment
    }
  }
`

const OrganizationFinixMerchantsFragment = gql`
  fragment OrganizationFinixMerchantsFragment on OrganizationFinixMerchant {
    id
    finixMerchantId
    description
  }
`

const UserDataFragmentWithRecordTypeAccesses = gql`
  fragment UserDataFragmentWithRecordTypeAccesses on User {
    ...UserDataFragment
    recordTypeAccesses {
      id
      recordType {
        id
        name
      }
    }
  }
`

const LocationSnapshotInDepthFragment = gql`
  fragment LocationSnapshotInDepthFragment on LocationSnapshot {
    id
    locationObjectId
    addressDisplayName
    parcelDisplayName
    parcelCoordinates {
      latitude
      longitude
    }
    fields {
      key
      value
    }
  }
`

const AddressFieldFragment = gql`
  fragment AddressFieldFragment on Field {
    id
    label
    str
    locationSnapshot {
      ...LocationSnapshotInDepthFragment
    }
  }
`

const AddressFieldGroupFragment = gql`
  fragment AddressFieldGroupFragment on FieldGroup {
    id
    fields {
      ...AddressFieldFragment
    }
  }
`

const RecordTemplateScheduledEventFragment = gql`
  fragment RecordTemplateScheduledEventFragment on RecordTemplateScheduledEvent {
    id
    changeToStatusId
    emailApplicant
    emailOwner
    emailTemplateId
    isExpiration
    type
    beginWorkflowTemplate {
      id
    }
    dateTemplate {
      ...DateTemplateFragment
    }
    recipients {
      userId
      emailAddress
    }
  }
`

const OrganizationLetterheadFragment = gql`
  fragment OrganizationLetterheadFragment on OrganizationLetterhead {
    id
    left
    right
    useCenterFile
    centerText
    isValid
    centerFile {
      ...FileFragment
    }
  }
`

const PaymentButtonRecordTaskPayment = gql`
  fragment PaymentButtonRecordTaskPayment on RecordTaskPayment {
    id
    organizationFinixMerchant {
      id
      finixMerchantId
    }
    totalWhenInvoiceRequestedCents
    recordTaskFees {
      id
      calculationExplanation
      quantity
      total
      feeVersion {
        id
        type
        fee {
          id
          name
        }
      }
    }
  }
`

const PlanReviewCoordinateFragment = gql`
  fragment PlanReviewCoordinateFragment on PlanReviewCoordinate {
    id
    x
    y
    order
  }
`

const PlanReviewMeasurementCalibrationFragment = gql`
  fragment PlanReviewMeasurementCalibrationFragment on PlanReviewMeasurementCalibration {
    id
    unit
    unitCount
    pageWidthPx
    pageHeightPx
    filePageId
    reviewId
    endCoordinate {
      ...PlanReviewCoordinateFragment
    }
    startCoordinate {
      ...PlanReviewCoordinateFragment
    }
  }
`

const PlanReviewMeasurementFragment = gql`
  fragment PlanReviewMeasurementFragment on PlanReviewMeasurement {
    id
    measurementType
    filePageId
    reviewId
    calibrationId
    coordinates {
      ...PlanReviewCoordinateFragment
    }
    area
    centroid {
      x
      y
    }
  }
`

const RecordTemplateFragmentForReporting = gql`
  fragment RecordTemplateFragmentForReporting on RecordTemplate {
    id
    name
    applicationFields {
      id
      label
      type
    }
  }
`

const ReportFilterFragment = gql`
  fragment ReportFilterFragment on ReportFilter {
    id
    name
    description
    params
    type
    filterType
    reportDisplayType
    recordTypeId
    reportFields {
      id
    }
    recordTemplates {
      ...RecordTemplateFragmentForReporting
    }
  }
`

const WorkflowMostRecentPlansSetFragment = gql`
  fragment WorkflowMostRecentPlansSetFragment on RecordPlansSet {
    versionNumber
    id
    allReviewsDoneOffline
    readyForApplicantUpdate
    reviews {
      ...WorkflowPlansSetReviewFragment
    }
  }
  fragment WorkflowPlansSetReviewFragment on RecordPlansSetReview {
    id
    type
    totalComments
    totalCommentsRespondedByApplicant
    offlineFile {
      ...FileFragment
    }
    rejected
    recordPlansSetId
    recordTask {
      id
      dueDate
      ownerUser {
        ...UserDataFragment
      }
      status
    }
    result {
      id
      name
      isPassing
    }
    deleteReviewPlansSetDecision
  }
`

const PlanReviewMostRecentPlansSetReadyFragment = gql`
  fragment PlanReviewMostRecentPlansSetReadyFragment on Record {
    id
    mostRecentPlansSet {
      id
      readyForApplicantUpdate
    }
  }
`

const RecipientFragment = gql`
  fragment RecipientFragment on Recipient {
    id
    emailAddress
    basedOnField {
      ...RecipientContactFieldFragment
    }
    contact {
      ...ContactFragment
    }
    recordAccess {
      ...RecordAccessFragment
    }
    user {
      ...UserDataFragment
    }
  }
`

const RecordScheduledEventFragment = gql`
  fragment RecordScheduledEventFragment on RecordScheduledEvent {
    id
    date
    emailApplicant
    emailContent
    emailOwner
    emailSignature
    emailSubject
    isExpiration
    type
    addRecordTaskTemplateGroup {
      id
      identifier
      type
    }
    beginWorkflowTemplate {
      id
      name
    }
    changeToStatus {
      id
      name
      type
    }
    emailFiles {
      ...FileFragment
    }
    emailTemplate {
      ...EmailTemplateFragment
    }
    recipients {
      ...RecipientFragment
    }
    recordTemplateScheduledEvent {
      id
    }
  }
`

const RecordScheduledEventStatusOptionFragment = gql`
  fragment RecordScheduledEventStatusOptionFragment on RecordTypeStatus {
    id
    type
    name
    recordTypeId
  }
`

const DateTemplateFragment = gql`
  fragment DateTemplateFragment on DateTemplate {
    type
    date {
      day
      month
      minimumDelayDays
    }
    interval {
      count
      type
    }
  }
`

const RecordTypeStatusFragment = gql`
  fragment RecordTypeStatusFragment on RecordTypeStatus {
    id
    name
    type
    isResolved
  }
`

const InspectionTemplatesCellInspectionTemplateFragment = gql`
  fragment InspectionTemplatesCellInspectionTemplateFragment on InspectionTemplate {
    ...InspectionTemplateFragment
    inputsCount
  }
`

const CreateViolationFragment = gql`
  fragment CreateViolationFragment on Violation {
    id
    identifier
  }
`

// order matters - some fragments reference other ones!
fragmentRegistry.register(
  BaseFileFragment,
  FileFragment,
  ThumbnailFileFragment,
  FilePageFragment,
  FileUploadCredentialsFragment,
  FieldFragment2,
  PlanReviewFileFragment,
  PlanReviewFieldGroupFragment,
  UserDataFragmentWithCompany,
  FieldGroupFragment,
  TaskTemplatesFragment,
  ViolationFieldsFragment,
  ViolationTypeHistoryFragment,
  ViolationTypeHistoryFragmentNoUserData,
  ContactFragment,
  ViolationInspectionsFragment,
  UserDataFragment,
  OrganizationFragmentNoUsers,
  OrganizationFragmentWithUsers,
  RecordFragmentOverview,
  RecordFragmentOverviewForApplicant,
  PlanReviewCommentFragment,
  PlanReviewStampFragment,
  EmailTemplateFragment,
  DocumentTemplateFragment,
  GeneralLedgerNumberFragment,
  FeeVersionFragment,
  FeeFragment,
  PaymentTransferFragment,
  RecordTaskFragment,
  InspectionTemplateFragment,
  InspectionResultFragment,
  RecordTaskInspectionAttemptFragment,
  RecordTaskInspectionFragment,
  RecordTaskInspectionGroupFragment,
  RecordTypeFragment,
  RecordTypeApplicationGroupFragment,
  CompanyFragment,
  DocumentTemplateItemParamsFragment,
  RecordTemplatePickFragment,
  ApplyFormRecordTemplateFragment,
  RecordTypePickFragment,
  FieldGroupFragmentWithBasedOnFieldIds,
  RecordTaskPaymentFragment,
  RecordTaskFeeFragment,
  GenerateDocumentTemplateFragment,
  ViewRecordTaskInspectionAttemptFragment,
  RecordTaskPlanReviewOverviewFragment,
  RecordTaskFeeValuationFragment,
  RecordComponentRecordTaskPaymentFragment,
  RecordComponentRecordTaskFeeFragment,
  RecordComponentPaymentTransferFragment,
  OrganizationFinixMerchantsFragment,
  UserDataFragmentWithRecordTypeAccesses,
  LocationSnapshotInDepthFragment,
  AddressFieldFragment,
  AddressFieldGroupFragment,
  RecordTemplateScheduledEventFragment,
  OrganizationLetterheadFragment,
  RecordTypeApplyFragment,
  PlanReviewLineArrowFragment,
  PlanReviewCircleFragment,
  PlanReviewQuadrilateralFragment,
  UserNameFragment,
  PaymentButtonRecordTaskPayment,
  PlanReviewCoordinateFragment,
  PlanReviewMeasurementCalibrationFragment,
  PlanReviewMeasurementFragment,
  RecordTemplateFragmentForReporting,
  ReportFilterFragment,
  WorkflowMostRecentPlansSetFragment,
  PlanReviewMostRecentPlansSetReadyFragment,
  RecordScheduledEventFragment,
  RecordScheduledEventStatusOptionFragment,
  DateTemplateFragment,
  RecordTypeStatusFragment,
  RecordEmailFragment,
  RecordEmailFileFragment,
  InspectionTemplatesCellInspectionTemplateFragment,
  RecipientContactFieldFragment,
  RecipientFragment,
  CreateViolationFragment,
  EnumerationFragment,
  FormulaFragment,
  FormulaOptionFragment,
  WorkflowTemplateOptionFragment,
  RecordAccessFragment,
  FileOnRecordFragment,
  RecordTemplateOptionFragment,
  RecordTaskTemplateGroupRequestedRecordFragment,
  WorkflowOptionFragment,
  RecordOptionFragment,
  RelatedRecordFragment,
  RecordFragment,
  ViolationFragment,
  ViolationTypeFragment,
  ViolationCodeFragment,
  ViolationMailerTitleFragment,
  ViolationInspectionFragment,
  ViolationMailerFragment,
  ViolationFileFragment,
  ViolationCommentFragment,
  RecordTaskTemplateGroupOptionFragment,
  RecordSettingsFragment,
  CodeBookItemFragment,
  CodeBookItemOptionFragment,
  CodeBookFragment,
  PlanReviewRecordFragment,
  RecordTaskChecklistItemFragment,
  WorkflowRecordTaskFragment,
  WorkflowRecordTaskGroupFragment,
  WorkflowFragment,
  GeneratedFileFragment
)
