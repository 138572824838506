import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { faChevronDown, faX } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Skeleton, Tag } from 'antd'
import { Popover } from 'govwell-ui'
import FormControl from 'govwell-ui/components/FormControl/FormControl'
import InputBase from 'govwell-ui/components/Input/InputBase'
import { useMenuKeyboardShortcuts } from 'govwell-ui/components/Menu/use-menu-keyboard-shortcuts'
import MultiSelectItem from 'govwell-ui/components/Select/MultiSelectItem'
import SelectMenuContent from 'govwell-ui/components/Select/SelectMenuContent'
import { SelectBaseProps, SelectOption } from 'govwell-ui/components/Select/types'
import { useSelectKeyboardShortcuts } from 'govwell-ui/components/Select/use-select-keyboard-shortcuts'
import { useFilteredOptions } from 'govwell-ui/components/Select/util'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import EmptyState from 'src/components/EmptyState'
import Text, { TextSize } from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'

const StyledLoadingState = styled(Flex).attrs({
  vertical: true,
  gap: '6px',
})`
  width: 100%;
  max-width: var(--radix-popper-anchor-width);
  padding: 6px;
  .ant-skeleton-input {
    height: 32px !important;
    width: 100% !important;
  }
`

const StyledTag = styled(Tag)`
  margin: 0;
  height: auto;
  background-color: ${({ theme }) => theme.colorBgContainerDisabled};
  border: solid 1px ${({ theme }) => theme.colorSplit};
  border-radius: 4px;
`
const StyledXButton = styled(Text).attrs({
  'aria-hidden': true,
  size: TextSize.ExtraSmall,
})`
  outline: solid 1px transparent;
  border-radius: 4px;
  margin-left: 3px;
  padding: 0px 3px;
  cursor: pointer;
  align-self: center;
  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorPrimaryActive};
  }
`

type LayoutProps =
  | {
      itemLayout?: never
      gridItemWidth?: never
    }
  | {
      itemLayout: 'flex-column'
      gridItemWidth?: never
    }
  | {
      itemLayout: 'grid'
      gridItemWidth?: string
    }

type Props<TValue> = SelectBaseProps<TValue> & {
  selectedOptions: SelectOption<TValue>[] | undefined
  onSelectedOptionsChange: (selectedOptions: SelectOption<TValue>[]) => void | Promise<unknown>
} & LayoutProps
const MultiSelect = <TValue,>({
  caption,
  filterOption,
  getOptionKey,
  gridItemWidth,
  isAsync = false,
  isClearable,
  isDisabled,
  isLoading,
  isRequired,
  itemLayout,
  label,
  onSearchQueryChange,
  placeholder = 'Select',
  prefixIcon,
  onBlur: propOnBlur,
  onSelectedOptionsChange,
  options,
  selectedOptions,
  size,
  width,
}: Props<TValue>) => {
  const [id] = useState(uuid())
  const [menuId] = useState(uuid())
  const [searchQuery, setSearchQuery] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const { isOpen: hasFocus, open: onFocus, close: onBlur } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const { isOpen, open, close } = useDisclosure()

  const selectedOptionsByKey = useMemo(
    () =>
      new Map<React.Key, SelectOption<TValue>>(selectedOptions?.map((o) => [getOptionKey(o), o])),
    [getOptionKey, selectedOptions]
  )

  const filteredOptions = useFilteredOptions({
    isAsync,
    options,
    searchQuery,
    filterOption,
  })

  const handleBackSpace = useCallback(() => {
    if (searchQuery?.length > 0 || !selectedOptions?.length) {
      return
    }
    const newSelectedOptions = selectedOptions?.slice() ?? []
    newSelectedOptions.pop()
    void onSelectedOptionsChange(newSelectedOptions)
    inputRef.current?.focus()
  }, [onSelectedOptionsChange, selectedOptions, searchQuery?.length])

  const handleClose = useCallback(() => {
    close()
    setActiveIndex(0)
    setSearchQuery('')
  }, [close])

  const handleOpen = useCallback(() => {
    if (isDisabled) {
      return
    }
    inputRef.current?.focus()
    open()
  }, [isDisabled, open])

  const handleBlur = useCallback(() => {
    onBlur()
    propOnBlur?.()
  }, [onBlur, propOnBlur])

  const handleClear = useCallback(() => {
    void onSelectedOptionsChange([])
    onFocus()
  }, [onFocus, onSelectedOptionsChange])

  const handleValueChange = useCallback(
    (newValue: string) => {
      setSearchQuery(newValue)
      setActiveIndex(0)
      open()
    },
    [open]
  )

  useSelectKeyboardShortcuts({
    hasFocus,
    isOpen,
    onBackSpace: handleBackSpace,
    onClose: handleClose,
    onOpen: handleOpen,
  })

  const handleOptionToggled = useCallback(
    (option: SelectOption<TValue>) => {
      const toggledKey = getOptionKey(option)
      let newSelectedOptions = selectedOptions?.slice() ?? []
      if (selectedOptionsByKey.has(toggledKey)) {
        selectedOptionsByKey.delete(toggledKey)
        newSelectedOptions = newSelectedOptions.filter((o) => o.value !== option.value)
      } else {
        selectedOptionsByKey.set(toggledKey, option)
        newSelectedOptions.push(option)
      }
      setSearchQuery('')
      void onSelectedOptionsChange(newSelectedOptions)
      inputRef.current?.focus()
    },
    [getOptionKey, onSelectedOptionsChange, selectedOptions, selectedOptionsByKey]
  )

  const activeOption = filteredOptions[activeIndex]
  const { menuItemRefs } = useMenuKeyboardShortcuts({
    activeIndex,
    count: options.length,
    isEnabled: isOpen,
    onEscape: handleClose,
    onSelect: () => {
      if (!activeOption) {
        return
      }
      handleOptionToggled(activeOption)
    },
    setActiveIndex,
  })

  useEffect(() => {
    // Reset active index when options change
    setActiveIndex(0)
  }, [filteredOptions])

  useEffect(() => {
    // Report changes in value
    onSearchQueryChange?.(searchQuery ?? '')
  }, [onSearchQueryChange, searchQuery])

  const getOptionId = useCallback(
    (option: SelectOption<TValue>) => `${id}-${getOptionKey(option)}`,
    [getOptionKey, id]
  )

  return (
    <FormControl caption={caption} id={id} isRequired={isRequired} label={label} width={width}>
      <Popover isOpen={isOpen} onOpen={handleOpen} onClose={handleClose}>
        <Popover.Trigger asChild>
          <div>
            <InputBase
              ariaActiveDescendant={activeOption ? getOptionId(activeOption) : undefined}
              ariaControls={menuId}
              ariaExpanded={isOpen}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isLoading={isLoading}
              isRequired={isRequired}
              onClear={handleClear}
              onBlur={handleBlur}
              onFocus={onFocus}
              onValueChange={handleValueChange}
              placeholder={!selectedOptions?.length ? placeholder : undefined}
              prefixIcon={prefixIcon}
              prefix={
                <>
                  {selectedOptions?.map((o) => (
                    <StyledTag key={getOptionKey(o)}>
                      <Flex gap="3px">
                        <Text whiteSpace="wrap">{o.label}</Text>
                        <StyledXButton
                          onClick={(e) => {
                            handleOptionToggled(o)
                            e.preventDefault() // Prevent deleting from closing the dropdown by stealing focus
                            handleOpen()
                          }}
                        >
                          <FontAwesomeIcon icon={faX} />
                        </StyledXButton>
                      </Flex>
                    </StyledTag>
                  ))}
                </>
              }
              ref={inputRef}
              role="combobox"
              size={size}
              suffixIcon={faChevronDown}
              value={searchQuery}
            />
          </div>
        </Popover.Trigger>
        <SelectMenuContent
          id={menuId}
          aria-multiselectable={true}
          $layout={itemLayout}
          $gridItemWidth={gridItemWidth}
        >
          {filteredOptions.map((o, index) => (
            <MultiSelectItem<TValue>
              key={`${o.group}-${getOptionKey(o)}`}
              id={getOptionId(o)}
              index={index}
              isActive={index === activeIndex}
              isSelected={selectedOptionsByKey.has(getOptionKey(o))}
              onMouseOver={setActiveIndex}
              onClick={handleOptionToggled}
              option={o}
              options={options}
              menuItemRefs={menuItemRefs}
            />
          ))}
          {!filteredOptions.length && !isLoading && (
            <EmptyState hideBorder size="sm">
              <EmptyState.Image />
              <EmptyState.Message>No options found</EmptyState.Message>
            </EmptyState>
          )}
          {!filteredOptions.length && isLoading && (
            <StyledLoadingState>
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
            </StyledLoadingState>
          )}
        </SelectMenuContent>
      </Popover>
    </FormControl>
  )
}

export default React.memo(MultiSelect) as typeof MultiSelect
