import { ReactNode, forwardRef, useCallback } from 'react'

import {
  IconDefinition,
  faArrowUpRightFromSquare,
  faCheck,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Flex, Form, FormInstance, Row, RowProps, Tooltip } from 'antd'
import AntdTitle from 'antd/es/typography/Title'
import styled from 'styled-components'

import { Link, back, navigate } from '@redwoodjs/router'

import { RequiredMark } from 'src/components/form/RequiredMark'

import { AddressFieldFragment } from '../../../types/graphql'
import { ExternalLinkProps } from '../../constants'
import { ColorTheme } from '../../constants/theme/colors'
import { ColorToken } from '../../constants/theme/types'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { HoverProps } from '../../hooks/use-hover'
import { useTheme } from '../../hooks/use-theme'
import { LottiesLoading } from '../../lotties/loading'
import { getIcon, openUrlInNewTab } from '../../utils'
import LottieAnimation from '../Lottie'
import { HorizontalLine } from '../StyledComponents'
import Text, { TextSize } from '../Typography/Text'

export interface StylePropsMobile {
  $small: boolean
}

export const IconContentRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

interface FormDualInputContainerProps {
  children: ReactNode
}

const StyledFormDualInputContainer = styled.div<StylePropsMobile>`
  display: flex;
  gap: 24px;
  ${(props) => props.$small && 'flex-direction: column;'}
`
export const FormDualInputContainer = (props: FormDualInputContainerProps) => {
  const { children } = props
  const { isSmallerThanOrEqual } = useCurrentBreakpoint()
  return (
    <StyledFormDualInputContainer $small={isSmallerThanOrEqual('sm')}>
      {children}
    </StyledFormDualInputContainer>
  )
}

export const getContainerStyleProps = (theme: ColorTheme) => {
  return `
    border-top: 1px solid ${theme.colorBordersecondary};
    border-right: 1px solid ${theme.colorBordersecondary};
    border-bottom: 1px solid ${theme.colorBordersecondary};
    border-left: 1px solid ${theme.colorBordersecondary};
    background-color: ${theme.colorFillAlter};
    border-radius: 8px;
`
}

export const removeContainerStyleProps = () => {
  return `
    border: unset;
    background-color: inherit;
    border-radius: 0px;
  `
}

export const getLightContainerStyleProps = (theme: ColorTheme, error?: boolean) => {
  return `
    border: 1px solid ${error ? theme.colorErrorBase : theme.colorBordersecondary};
    background-color: ${theme.colorWhite};
    border-radius: 8px;
  `
}

interface PageTitleProps {
  title: ReactNode
  subtitle?: ReactNode
  backButtonHref?: string
  primaryButton?: ReactNode
  icon?: string | null
  showRouterBackButton?: boolean
}

export const PageTitle = (props: PageTitleProps) => {
  const { title, subtitle, backButtonHref, primaryButton, icon, showRouterBackButton } = props
  const { getTokenVal } = useTheme()

  const showBackButton = !!backButtonHref || !!showRouterBackButton
  const BackButton = useCallback(
    () =>
      showBackButton ? (
        <Button
          onClick={() => (backButtonHref ? navigate(backButtonHref) : back())}
          id="back"
          type="default"
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
        >
          Back
        </Button>
      ) : null,
    [backButtonHref, showBackButton]
  )
  const Title = useCallback(
    () => (
      <AntdTitle
        level={3}
        style={{ margin: 0, flex: 1, display: 'flex', alignItems: 'center', gap: '8px' }}
        color={getTokenVal('colorText')}
      >
        {icon && <FontAwesomeIcon icon={getIcon(icon)} />}
        {title}
      </AntdTitle>
    ),
    [getTokenVal, icon, title]
  )
  const Subtitle = useCallback(
    () =>
      subtitle ? (
        <Text size={TextSize.ExtraLarge} colorToken="cyan-7">
          {subtitle}
        </Text>
      ) : null,
    [subtitle]
  )

  const { isSmallScreen } = useCurrentBreakpoint()
  if (isSmallScreen()) {
    return (
      <Flex gap="16px" justify="space-between" wrap="wrap" style={{ width: '100%' }}>
        <Flex vertical gap="4px">
          <Title />
          <Subtitle />
        </Flex>
        <Flex flex={1} justify="flex-end">
          {primaryButton}
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex vertical gap="8px" style={{ width: '100%' }}>
      <Flex justify="space-between" align="flex-start" gap="16px" style={{ width: '100%' }}>
        <BackButton />
        <Title />
        {primaryButton}
      </Flex>
      <Subtitle />
    </Flex>
  )
}

type CardTitleIconProps = {
  colorToken?: ColorToken
  tooltip?: string
  icon: IconDefinition
  fontSize?: number
}

export const CardTitleIcon = ({ colorToken, tooltip, icon, fontSize }: CardTitleIconProps) => {
  const { isSmallerThanOrEqual } = useCurrentBreakpoint()
  const small = isSmallerThanOrEqual('md')
  const { getTokenVal } = useTheme()

  if (!icon || small) {
    return null
  }

  return (
    <Tooltip title={tooltip}>
      <FontAwesomeIcon
        icon={icon}
        style={{
          fontSize: fontSize || 24,
          color: colorToken ? getTokenVal(colorToken) : undefined,
        }}
      />
    </Tooltip>
  )
}

export interface CardTitleProps {
  title: ReactNode
  margin?: string
  button?: ReactNode
  alignItems?: string
  iconProps?: CardTitleIconProps
  iconPosition?: 'left' | 'right'
  hoverProps?: HoverProps
}

interface TitleContainerProps {
  $alignItems?: string
}

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  align-items: ${(props) => props.$alignItems || 'center'};
  gap: 8px;
  width: 100%;
`
const TitleButtonContainer = styled.div`
  margin-left: auto;
`

export const CardTitle = (props: CardTitleProps) => {
  const {
    title,
    margin,
    button,
    alignItems,
    iconProps,
    hoverProps = {},
    iconPosition = 'left',
  } = props
  const { getTokenVal } = useTheme()

  const renderedIcon = iconProps ? <CardTitleIcon {...iconProps} /> : null

  return (
    <TitleContainer $alignItems={alignItems} {...hoverProps}>
      {iconPosition === 'left' && renderedIcon}
      <AntdTitle level={5} style={{ margin: margin || 0 }} color={getTokenVal('colorTextHeading')}>
        {title}
      </AntdTitle>
      {iconPosition === 'right' && renderedIcon}
      {button && <TitleButtonContainer>{button}</TitleButtonContainer>}
    </TitleContainer>
  )
}

const StyledSpinContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const LoadingSpin = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ width = 200, height = 200, ...props }, ref) => {
    return (
      <StyledSpinContainer {...props} ref={ref}>
        <LottieAnimation animationData={LottiesLoading} width={width} height={height} />
      </StyledSpinContainer>
    )
  }
)

interface AvatarProps {
  size: number
  backgroundColorToken: ColorToken
  borderColorToken?: ColorToken
  children: ReactNode
  bottomLeftTail?: boolean
}

interface AvatarContainerProps {
  $size: number
  $backgroundColor: string
  $borderColor?: string
  $bottomLeftTail: boolean
}
const AvatarContainer = styled.div<AvatarContainerProps>`
  height: ${(props) => props.$size}px;
  min-height: ${(props) => props.$size}px;
  width: ${(props) => props.$size}px;
  min-width: ${(props) => props.$size}px;
  background-color: ${(props) => props.$backgroundColor};
  ${({ $borderColor }) => ($borderColor ? `border: solid 1px ${$borderColor};` : '')};
  border-radius: ${(props) => (props.$bottomLeftTail ? '50% 50% 50% 0' : '50%')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Avatar = (props: AvatarProps) => {
  const { size, backgroundColorToken, borderColorToken, children, bottomLeftTail } = props
  const { getTokenVal } = useTheme()
  return (
    <AvatarContainer
      $size={size}
      $backgroundColor={getTokenVal(backgroundColorToken)}
      $borderColor={borderColorToken ? getTokenVal(borderColorToken) : undefined}
      $bottomLeftTail={!!bottomLeftTail}
      className="gw-avatar"
    >
      {children}
    </AvatarContainer>
  )
}

export const AvatarImage = styled.img<{ $size: number }>`
  border-radius: 50%;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  min-width: ${(props) => props.$size}px;
  min-height: ${(props) => props.$size}px;
`

export const StyledLink = styled.a`
  color: ${(props) => props.theme['cyan-7']};
  &:hover {
    color: ${(props) => props.theme['cyan-7']};
    font-weight: bold;
  }
  cursor: pointer;
  white-space: normal; // Ensures the text wraps appropriately without cutting off words
`
const TableLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`
interface TableLinkProps {
  href: string
  children: ReactNode
  enableNewTabIcon?: boolean
}
export const TableLink = (props: TableLinkProps) => {
  const { href, children, enableNewTabIcon } = props
  return (
    <TableLinkContainer>
      <Link to={href} href={href} className="table-link">
        {children}
      </Link>
      {enableNewTabIcon && (
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          onClick={() => openUrlInNewTab(href)}
          style={{
            width: 9,
            height: 9,
            color: ' rgb(8, 151, 156)',
            cursor: 'pointer',
            transition: 'transform 0.2s ease-in-out, color 0.2s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.1)'
            e.currentTarget.style.color = 'rgb(5, 120, 125)' // Darker shade for emphasis
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)'
            e.currentTarget.style.color = 'rgb(8, 151, 156)'
          }}
        />
      )}
    </TableLinkContainer>
  )
}

export const TableLinkNewPage = (props: TableLinkProps) => {
  const { href, children } = props
  return (
    <StyledLink href={href} {...ExternalLinkProps}>
      {children}
    </StyledLink>
  )
}

interface MailToLinkProps {
  emailAddress: string
  children: ReactNode
}
export const MailToLink = (props: MailToLinkProps) => {
  const { emailAddress, children } = props
  return (
    <StyledLink onClick={() => openUrlInNewTab(`mailto:${emailAddress}`)}>{children}</StyledLink>
  )
}

interface RecordAddressProps {
  // any record that extends addressField
  record: {
    addressField?: AddressFieldFragment | null
  }
}
export const RecordAddress = (props: RecordAddressProps) => {
  const {
    record: { addressField },
  } = props
  return addressField?.locationSnapshot?.addressDisplayName || addressField?.str || ''
}

interface LabelSublabelOptionProps {
  label: ReactNode
  sublabel?: ReactNode
}
export const LabelSublabelOption = (props: LabelSublabelOptionProps) => {
  const { label, sublabel } = props
  return (
    <>
      <Text size={TextSize.Base} strong>
        {label}
      </Text>
      {(typeof sublabel === 'string' ? sublabel.trim() : sublabel) && (
        <Text
          size={TextSize.Small}
          style={{
            height: 'fit-content',
            display: 'flex',
            whiteSpace: 'break-spaces',
          }}
        >
          {sublabel}
        </Text>
      )}
    </>
  )
}

export const RightAlignedCellContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

interface FormContentContainerProps {
  $gap?: string
  $fullHeight?: boolean
}
const FormContentContainer = styled.div<FormContentContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap || '24px'};
  ${(props) => props.$fullHeight && `height: 100%;`}
`

interface FormContainerProps<FormValues> {
  initialValues?: FormValues
  form: FormInstance<FormValues>
  children: ReactNode
  gap?: string
  fullHeight?: boolean
  fullWidth?: boolean
}
export function FormContainer<FormValues>(props: FormContainerProps<FormValues>) {
  const { initialValues, form, children, gap, fullHeight, fullWidth } = props
  const style = {
    ...(fullWidth ? { width: '100%' } : {}),
    ...(fullHeight ? { height: '100%' } : {}),
  }
  return (
    <Form
      {...(initialValues ? { initialValues } : {})}
      form={form}
      layout="vertical"
      requiredMark={RequiredMark}
      style={style}
      validateTrigger="onBlur"
    >
      <FormContentContainer $gap={gap} $fullHeight={fullHeight}>
        {children}
      </FormContentContainer>
    </Form>
  )
}

interface CardGridProps<T> {
  items: T[]
  renderItem: (item: T) => ReactNode
  makeKey: (item: T, index: number) => string
  justify?: RowProps['justify']
  cardsPerRow?: 2 | 3
  sort?: (item1: T, item2: T) => number
}
export function CardGrid<T>(props: CardGridProps<T>) {
  const { makeKey, renderItem, justify, sort } = props

  const items = sort ? props.items.sort(sort) : props.items
  const cardsPerRow = Math.min(props.items.length, props.cardsPerRow ?? 3)

  return (
    <Row gutter={[24, 24]} justify={justify || (items.length < 3 ? 'center' : 'start')}>
      {items.map((item: T, index: number) => (
        <Col
          key={makeKey(item, index)}
          xs={24}
          sm={12}
          lg={24 / cardsPerRow}
          style={{ display: 'flex', width: '100%' }}
        >
          {renderItem(item)}
        </Col>
      ))}
    </Row>
  )
}

export function FieldGrid<T>(props: CardGridProps<T>) {
  const { items, makeKey, renderItem } = props

  return (
    <Row gutter={[24, 24]} justify="start">
      {items.map((item: T, index: number) => (
        <Col
          key={makeKey(item, index)}
          xs={24}
          sm={12}
          lg={8}
          style={{ display: 'flex', width: '100%' }}
        >
          {renderItem(item)}
        </Col>
      ))}
    </Row>
  )
}

interface SingleRowEqualGridProps {
  contents: ReactNode[]
}
export const SingleRowEqualGrid = (props: SingleRowEqualGridProps) => {
  const { contents } = props

  const span = 24 / contents.length

  return (
    <Row gutter={[16, 16]}>
      {/* The gutter property sets the spacing between columns */}
      {contents.map((content, index) => (
        <Col key={index} xs={span}>
          {/* Adjust the xs, sm, md, lg properties for responsiveness */}
          {content}
        </Col>
      ))}
    </Row>
  )
}

interface CardContainerProps {
  $hover?: boolean
  $alignItems?: string
}
export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  ${(props) => getContainerStyleProps(props.theme)}
  ${(props) => props.$alignItems && `align-items: ${props.$alignItems};`}
  width: 100%;
  gap: 12px;
  height: 100%;
  ${(props) =>
    props.$hover &&
    `&:hover {
    border: 1px solid ${props.theme.colorPrimaryBase};
    cursor: pointer;
    }`}
`

interface TabContainerProps {
  $gap?: string
  $minHeight?: string
  $hovered?: boolean
}
export const TabContainer = styled.div<TabContainerProps>`
  ${(props) => getContainerStyleProps(props.theme)}
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.$gap || '24px'};
  ${(props) => props.$minHeight && `min-height: ${props.$minHeight};`}
  ${(props) => props.$hovered && `border-color: ${props.theme.colorPrimaryBase};`}
  ${(props) => props.$hovered && `cursor: pointer;`}
`

export const MultipleInputsPerLineContainer = styled.div<StylePropsMobile>`
  display: flex;
  gap: 24px;
  flex: 1;
  flex-direction: ${(props) => (props.$small ? 'column' : 'row')};
`

interface HorizontalLineWithTextProps {
  text: string
}
const HorizontalLineWithTextContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
export const HorizontalLineWithText = (props: HorizontalLineWithTextProps) => {
  const { text } = props
  return (
    <HorizontalLineWithTextContainer>
      <HorizontalLine />
      {text}
      <HorizontalLine />
    </HorizontalLineWithTextContainer>
  )
}

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.theme['green-1']};
  border: 2px solid ${(props) => props.theme.colorSuccessBase};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoldedCheckMark = () => {
  const { getTokenVal } = useTheme()
  return (
    <IconContainer>
      <FontAwesomeIcon
        icon={faCheck}
        style={{
          color: getTokenVal('colorSuccessBase'),
          height: '16px',
          width: '16px',
        }}
      />
    </IconContainer>
  )
}

export const IconOnlyButton = styled(Button)`
  border: none;
`
