import {
  CreateRecordTypeMutation,
  CreateRecordTypeMutationVariables,
  GetAllFieldTemplatesInRecordTypeQuery,
  GetAllFieldTemplatesInRecordTypeQueryVariables,
  RecordTypeForRecordTemplatesQuery,
  RecordTypeForRecordTemplatesQueryVariables,
  RecordTypeOverviewQuery,
  RecordTypeOverviewQueryVariables,
  RecordTypeQuery,
  RecordTypeQueryVariables,
  RecordTypesOverviewQuery,
  RecordTypesQuery,
  RecordTypesQueryVariables,
  RecordTypeStatusQuery,
  RecordTypeStatusQueryVariables,
  UpdateRecordTypeMutation,
  UpdateRecordTypeMutationVariables,
} from 'types/graphql'

import { QueryKey, useMutation, useQuery } from 'src/utils/queryClient'

const QueryRecordTypeStatus = gql`
  query RecordTypeStatusQuery($recordTypeId: Int!) {
    recordType(id: $recordTypeId) {
      statuses {
        ...RecordTypeStatusFragment
      }
    }
  }
`

export const useRecordTypeStatusesQuery = (args: { recordTypeId: number | null | undefined }) => {
  return useQuery<RecordTypeStatusQuery, RecordTypeStatusQueryVariables>({
    queryKey: [QueryKey.RecordTypeStatuses, args.recordTypeId],
    queryDocument: QueryRecordTypeStatus,
    variables: {
      recordTypeId: args.recordTypeId ?? -1,
    },
    enabled: !!args.recordTypeId,
  })
}

const RecordTypes = gql`
  query RecordTypesQuery($organizationId: Int!) {
    recordTypesForOrg(organizationId: $organizationId) {
      id
      name
      shouldShowInStaffNavigation
      visibleOnOrganizationHomepage
    }
  }
`
export const useRecordTypesQuery = (organizationId: number) => {
  return useQuery<RecordTypesQuery, RecordTypesQueryVariables>({
    queryKey: [QueryKey.RecordTypes, organizationId],
    queryDocument: RecordTypes,
    variables: { organizationId },
  })
}

const RecordType = gql`
  query RecordTypeQuery($recordTypeId: Int!) {
    recordType: recordType(id: $recordTypeId) {
      ...RecordTypeFragment
    }
  }
`
export const useRecordTypeQuery = (recordTypeId: number | null | undefined) => {
  return useQuery<RecordTypeQuery, RecordTypeQueryVariables>({
    queryKey: [QueryKey.RecordType, recordTypeId],
    queryDocument: RecordType,
    variables: {
      recordTypeId: recordTypeId ?? -1,
    },
    enabled: !!recordTypeId,
  })
}

const RecordTypeForRecordTemplates = gql`
  query RecordTypeForRecordTemplatesQuery($recordTypeId: Int!) {
    recordType(id: $recordTypeId) {
      ...ModulePageRecordTypeFragment
    }
  }
  fragment ModulePageRecordTypeFragment on RecordType {
    id
    recordTemplates {
      ...RecordTemplateForConfigFragment
    }
    category
  }
  fragment RecordTemplateForConfigFragment on RecordTemplate {
    id
    recordTypeId
    name
    tags
    applicantType
    uuid
    prefix
    visibleOnModulePickPage
    visibleOnOrganizationHomepage
    owner {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    applicationGroup {
      id
      name
    }
  }
`

export const useRecordTypeForConfigQuery = (recordTypeId: number) => {
  return useQuery<RecordTypeForRecordTemplatesQuery, RecordTypeForRecordTemplatesQueryVariables>({
    queryDocument: RecordTypeForRecordTemplates,
    queryKey: [QueryKey.RecordTypeForRecordTemplates, recordTypeId],
    variables: { recordTypeId },
  })
}

const RecordTypesOverview = gql`
  query RecordTypesOverviewQuery {
    recordTypes {
      ...RecordTypeOverviewFragment
    }
  }
  fragment RecordTypeOverviewFragment on RecordType {
    id
    icon
    name
    recordName
    displayName
    category
    shouldEmailCitizens
    shouldEmailStaff
    shouldShowInStaffNavigation
    visibleOnOrganizationHomepage
  }
`

export const useRecordTypesOverviewQuery = () => {
  return useQuery<RecordTypesOverviewQuery>({
    queryDocument: RecordTypesOverview,
    queryKey: [QueryKey.RecordTypesOverview],
  })
}

const Create = gql`
  mutation CreateRecordTypeMutation($input: CreateRecordTypeInput!) {
    createRecordType(input: $input) {
      id
    }
  }
`
export const useCreateRecordTypeMutation = () => {
  return useMutation<CreateRecordTypeMutation, CreateRecordTypeMutationVariables>({
    mutationDocument: Create,
  })
}

const Update = gql`
  mutation UpdateRecordTypeMutation($id: Int!, $input: UpdateRecordTypeInput!) {
    updateRecordType(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateRecordTypeMutation = () => {
  return useMutation<UpdateRecordTypeMutation, UpdateRecordTypeMutationVariables>({
    mutationDocument: Update,
  })
}

const GetAllFieldTemplatesInRecordType = gql`
  query GetAllFieldTemplatesInRecordTypeQuery($recordTypeId: Int!) {
    recordType(id: $recordTypeId) {
      recordTemplates {
        id
        name
        applicationFields {
          ...FieldFragment
        }
      }
    }
  }
`
export const useAllFieldTemplatesInRecordTypeQuery = (recordTypeId?: number) => {
  return useQuery<
    GetAllFieldTemplatesInRecordTypeQuery,
    GetAllFieldTemplatesInRecordTypeQueryVariables
  >({
    queryKey: [QueryKey.RecordTypeFields, recordTypeId],
    queryDocument: GetAllFieldTemplatesInRecordType,
    variables: {
      recordTypeId: recordTypeId ?? -1,
    },
    enabled: !!recordTypeId,
  })
}

const RecordTypeOverview = gql`
  query RecordTypeOverviewQuery($recordTypeId: Int!) {
    recordType(id: $recordTypeId) {
      ...RecordTypeForModulePageFragment
    }
  }
  fragment RecordTypeForModulePageFragment on RecordType {
    id
    displayName
    icon
    addressEntryType
    issuedIdentifierAlgorithm
    visibleOnOrganizationHomepage
    recordSettings {
      ...RecordSettingsFragment
    }
    allowInspections
    allowFees
    applyDescription
    category
    displayName
    icon
    icon
    isContact
    name
    recordName
    shouldEmailCitizens
    shouldEmailStaff
    shouldShowInStaffNavigation
    organization {
      id
      slug
      hasViolationsModule
    }
  }
`

export const useRecordTypeOverviewQuery = (recordTypeId: number) => {
  return useQuery<RecordTypeOverviewQuery, RecordTypeOverviewQueryVariables>({
    queryDocument: RecordTypeOverview,
    queryKey: [QueryKey.RecordTypeOverview, recordTypeId],
    variables: { recordTypeId },
  })
}
